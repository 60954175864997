<template>
    <div>
        <!-- 顶部工具条 -->
        <el-row>
            <el-col>
                <el-form :inline='true'>
                    <el-form-item label='关键字:'>
                        <el-input v-model='filters.name' placeholder='姓名/手机号/身份证号' clearable></el-input>
                    </el-form-item>
                    <el-form-item label='单位名称:'>
                        <el-input v-model='filters.socialUnitName' placeholder='单位名称' clearable></el-input>
                    </el-form-item>
                    <el-form-item label="岗位试卷:">
                        <el-select v-model="filters.station" placeholder="请选择岗位试卷" clearable>
                            <el-option
                                v-for="item in stationArr"
                                :key="item.Id"
                                :label="item.Name"
                                :value="item.Id"
                            >
                            </el-option>
                            <!-- <el-pagination
                                layout="prev, pager, next"
                                @current-change="stationChange"
                                :total="stationPage"
                            >
                            </el-pagination> -->
                        </el-select>
                    </el-form-item>
                    <el-form-item label="行业试卷:">
                        <el-select v-model="filters.trade" placeholder="请选择行业试卷" clearable>
                            <el-option
                                v-for="item in tradeArr"
                                :key="item.Id"
                                :label="item.Name"
                                :value="item.Id"
                            >
                            </el-option>
                            <!-- <el-pagination
                                layout="prev, pager, next"
                                @current-change="tradeChange"
                                :total="tradePage"
                            >
                            </el-pagination> -->
                        </el-select>
                    </el-form-item>
                    <el-form-item label="成绩:">
                        <el-select v-model="filters.QualifiedStatus" placeholder="请选择成绩" clearable>
                            <el-option
                                v-for="item in QualifiedStatusArr"
                                :key="item.value"
                                :label="item.Name"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="免培考试:">
                        <el-select v-model="filters.isExamNoTrain" placeholder="请选择是否免培考试" clearable>
                            <el-option
                                v-for="item in isExamNoTrainArr"
                                :key="item.value"
                                :label="item.Name"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="交卷原因:">
                        <el-select v-model="filters.handReason" placeholder="请选择是否免培考试" clearable>
                            <el-option
                                v-for="item in handReasonArr"
                                :key="item.value"
                                :label="item.Name"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否删除:">
                        <el-select v-model="filters.isDeletedStatus" placeholder="请选择是否删除" clearable>
                            <el-option
                                v-for="item in isExamNoTrainArr"
                                :key="item.value"
                                :label="item.Name"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>                           
            </el-col>         
        </el-row>
        <!-- 表格主体 -->
        <el-table
            v-loading='listLoading'
            :data="tableData"
            border
            style="width: 100%"
            highlight-current-row
            @current-change='currentChange'
            :cell-style='rowClass'
            :header-cell-style='headerStyle'
            :row-class-name="tableRowClassName"
        >   
            <el-table-column type='index' label='序号' width='60' :index='indexMethod'></el-table-column>
            <el-table-column prop="Name" label="姓名" width="90"></el-table-column>
            <el-table-column prop="PhoneNumber" label="手机号" width="130"></el-table-column>
            <el-table-column prop="CardNo" label="身份证号" width="180"></el-table-column>
            <!-- <el-table-column prop="PhoneNumber" label="单位名称"></el-table-column> -->
            <el-table-column prop="AgencyInfoName" label="培训机构" show-overflow-tooltip min-width="100">
                <template slot-scope='scope'>
                    <span type='success' v-if='scope.row.ExamNoTrain == 1'>-</span>
                    <span type='danger' v-else>{{scope.row.AgencyInfoName}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="StationName" label="岗位试卷" show-overflow-tooltip min-width="100"></el-table-column>
            <el-table-column prop="TradeName" label="行业试卷" show-overflow-tooltip min-width="100"></el-table-column>
            <el-table-column prop="UnitName" label="所在单位" show-overflow-tooltip min-width="100"></el-table-column>
            <el-table-column prop="TotalScore" label="成绩" width="80"></el-table-column>
            <el-table-column prop="TotalScore" label="状态" width="90">
                <template slot-scope='scope'>
                    <el-tag type='' v-if='scope.row.Status == 1'>考试中</el-tag>
                    <el-tag type='success' v-else-if='!scope.row.IsDeleted && scope.row.Qualified'>及格</el-tag>
                    <el-tag type='info' v-else-if='scope.row.IsDeleted && scope.row.Qualified'>及格</el-tag>
                    <el-tag type='danger' v-else-if='scope.row.IsDeleted && !scope.row.Qualified'>不及格</el-tag>
                    <el-tag type='danger' v-else>不及格</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop='HandTime' label='交卷时间' min-width="100"></el-table-column>
            <el-table-column prop='ExceptionType' label='交卷原因' min-width="100">
                <template slot-scope='scope'>
                    <span v-if='scope.row.Status == 1 && scope.row.IsDeleted'>放弃考试</span>
                    <span v-else-if='scope.row.ExceptionType == 0'>正常交卷</span>
                    <span v-else-if='scope.row.ExceptionType == 1'>达到违规数</span>
                    <span v-else-if='scope.row.ExceptionType == 2'>截屏</span>
                    <span v-else-if='scope.row.ExceptionType == 3'>录屏或投屏</span>
                    <span v-else-if='scope.row.ExceptionType == 4'>照片抓拍失败</span>
                    <span v-else-if='scope.row.ExceptionType == 5'>异常操作</span>
                    <span v-else-if='scope.row.ExceptionType == 6'>闪退</span>
                    <span v-else-if='scope.row.ExceptionType == 7'>网络未连接</span>
                    <span v-else-if='scope.row.ExceptionType == 8'>考试超时</span>
                    <span v-else-if='scope.row.ExceptionType == 10'>切屏或锁屏</span>
                    <span v-else>未知</span>
                </template>
            </el-table-column>
            <el-table-column prop="ExamNoTrain" label="是否免培考试" min-width="120">
                <template slot-scope='scope'>
                    <el-tag type='success' v-if='!scope.row.IsDeleted && scope.row.ExamNoTrain == 1'>是</el-tag>
                    <el-tag type='info' v-else-if='scope.row.IsDeleted && scope.row.ExamNoTrain == 1'>是</el-tag>
                    <el-tag type='info' v-else-if='scope.row.IsDeleted && scope.row.ExamNoTrain != 1'>否</el-tag>
                    <el-tag type='danger' v-else>否</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="TakePhotos" label="抓拍照片" show-overflow-tooltip align="center" min-width="100">
                <template scope="scope">
                    <el-button type="text" @click="viewBatPicture(scope.row)">查看</el-button>
                </template>
            </el-table-column>
            <el-table-column prop="TakePhotos" label="人声监测" show-overflow-tooltip align="center" min-width="100">
                <template scope="scope">
                    <el-button type="text" @click="viewBatSound(scope.row)">查看</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pages.PageIndex"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pages.PageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pages.DataCount"
            class='pageBar'
        >
        </el-pagination>
        <!-- 查看照片 -->
        <el-dialog
            :title="dialogTitle"
            :visible.sync="viewPictureDialog"
            width="50%"
            :close-on-click-modal='true'
        >
            <el-table
                v-loading='viewPictureLoading'
                :data="viewPictureTable"
                border
                highlight-current-row
            >   
                <el-table-column type='index' label='序号' width='60' align="center"></el-table-column>
                <el-table-column prop="CreateTime" label="抓拍时间" align="center"></el-table-column>
                <el-table-column prop="DataUrl" label="照片" align="center">
                    <template slot-scope='scope'>
                        <el-popover placement='right' trigger='hover'>
                            <img :src="scope.row.DataUrl" alt="" style='width:120px;height:150px;text-align:center'> 
                            <img slot="reference" :src="scope.row.DataUrl" alt="" style='width: auto;height: auto;max-width: 30px;max-height: 30px;text-align:center'>                   
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column prop="Status" label="对比结果" align="center">
                    <template slot-scope='scope'>
                        <el-tag type='success' v-if='scope.row.Status == 1'>成功</el-tag>
                        <el-tag type='danger' v-else-if='scope.row.Status == 0'>人脸对比失败</el-tag>
                        <el-tag type='danger' v-else-if='scope.row.Status == 2'>人脸未正对摄像头</el-tag>
                        <el-tag type='danger' v-else-if='scope.row.Status == 4'>出现多个人脸</el-tag>
                        <el-tag type='info' v-else>未知</el-tag>
                    </template>
                </el-table-column>
            </el-table>  
            <span slot="footer" class="dialog-footer">
                <el-button @click="viewPictureDialog = false">关 闭</el-button>
            </span>
        </el-dialog>
        <!-- 人声监测 -->
        <el-dialog
            :title="soundDialogTitle"
            :visible.sync="viewSoundDialog"
            width="70%"
            :close-on-click-modal='true'
        >
            <el-table
                v-loading='viewSoundLoading'
                :data="viewSoundTable"
                border
                highlight-current-row
            >   
                <el-table-column type='index' label='序号' width='60' align="center"></el-table-column>
                <el-table-column prop="DataUrl" label="音频" align="center">
                    <template slot-scope='scope'>
                        <a :href="scope.row.DataUrl" target="_blank">{{scope.row.DataUrl}}</a>
                    </template>
                </el-table-column>
                <el-table-column prop="DataUrl" label="音频翻译" align="center">
                    <template slot-scope='scope'>
                        {{JSON.parse(scope.row.ResultJson)[0].result.text}}
                    </template>
                </el-table-column>
                <el-table-column prop="CreateTime" label="监测时间" align="center" width="180"></el-table-column>
            </el-table>  
            <span slot="footer" class="dialog-footer">
                <el-button @click="viewSoundDialog = false">关 闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getButtonList } from "@/promissionRouter";
import Toolbar from "../../components/Toolbar";
import Qs from 'qs';
import util from "../../../util/date";
import { getStudentAchievementListPage,getDataDictionaryList,getTakePhotosList,getRecordingMonitoringList,deleteStudentExamineePaperById } from '@/api/api'
export default {
    components:{Toolbar},
    data(){
        return {
            buttonList:[],
            filters:{
                name:'',
                socialUnitName:'',
                station:'',
                trade:'',
                QualifiedStatus:'',
                isExamNoTrain:'',
                handReason: '',
                isDeletedStatus: 0,
            },
            stationArr: [],
            stationPageIndex: 1,
            stationPage: 10,
            tradeArr: [],
            tradePageIndex: 1,
            tradePage: 10,
            QualifiedStatusArr: [
                {
                Name: '及格',
                value: 1,
            },{
                Name: '不及格',
                value: 0,
            }
            ],
            handReasonArr: [
                {Name: '正常交卷',value: 0,},
                {Name: '达到违规次数',value: 1,},
                {Name: '截屏',value: 2,},
                {Name: '录屏或投屏',value: 3,},
                {Name: '切屏或锁屏',value: 10,},
                {Name: '闪退',value: 6,},
                {Name: '网络未连接',value: 7,},
                {Name: '考试超时',value: 8,},
                {Name: '其他',value: -1,},
            ],
            isExamNoTrainArr: [{
                Name: '是',
                value: 1,
            },{
                Name: '否',
                value: 0,
            }],
            tableData:[],
            currentRow: "",
            pages:{
                PageIndex:1,
                PageSize:20,
                DataCount:0
            },
            listLoading:false,
            // 查看照片
            dialogTitle: '抓拍照片记录',
            viewPictureDialog: false,
            viewPictureLoading: false,
            viewPictureTable: [],
            // 人声监测
            soundDialogTitle: '人声监测记录',
            viewSoundDialog: false,
            viewSoundLoading: false,
            viewSoundTable: [],
        }
    },
    methods:{
        // 列表样式
        rowClass(){
            return 'text-align:center;'
        },
        headerStyle(){
            return 'text-align:center;'
        },
        // 页面数据
        getData(){
            this.listLoading = true
            var params = {
                name:this.filters.name,
                socialUnitName:this.filters.socialUnitName,
                examNoTrain:this.filters.isExamNoTrain,
                handReason:this.filters.handReason,
                isQualified:this.filters.QualifiedStatus,
                stationId:this.filters.station,
                tradeId:this.filters.trade,
                isDeleted:this.filters.isDeletedStatus,
                pageIndex:this.pages.PageIndex,
                pageSize:this.pages.PageSize
            }
            getStudentAchievementListPage(params).then(res => {
                this.listLoading = false
                if(res.data.Success){
                    this.tableData = res.data.Response.Data
                    this.pages.DataCount = res.data.Response.DataCount
                    this.currentRow = ""
                }else{
                    this.$message.error(res.data.Message)
                }
            })
        },
        // 获取岗位试卷
        getStationPaper() {
            var params = {
                dataType: 1,
	            isEnable:true,//是否启用
                // pageIndex: this.stationPageIndex,
                // pageSize: 10,
            }
            getDataDictionaryList(params).then(res => {
                var result = res.data
                if(result.Success) {
                    this.stationArr = result.Response
                    // this.stationPage = result.Response.DataCount
                }
            })
        },
        stationChange(val) {
            this.stationPageIndex = val
            this.getStationPaper();
        },
        // 获取行业试卷
        getTradePaper() {
            var params = {
                dataType: 0,
	            isEnable:true,//是否启用
                // pageIndex: this.tradePageIndex,
                // pageSize: 10,
            }
            getDataDictionaryList(params).then(res => {
                var result = res.data
                if(result.Success) {
                    this.tradeArr = result.Response
                    // this.tradePage = result.Response.DataCount
                }
            })
        },
        tradeChange(val) {
            this.tradePageIndex = val
            this.getTradePaper();
        },
        // 删除考试记录
        handleDel() {
            const row = this.currentRow
            if(!row){
                this.$message.warning('请选择要删除的数据');
            } else if(row.IsDeleted && row.Status == 2) {
                this.$message.warning('已删除记录无需再次删除！');
            } else {
                this.$confirm('确定删除考试记录?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    deleteStudentExamineePaperById({id:row.ID}).then(res => {
                        if(res.data.Success){
                            this.$message({
                                message: res.data.Message,
                                type: 'success'
                            });
                            this.getData()
                        }else{
                            this.$message.error(res.data.Message);
                        }
                    }).catch(() => {})
                }).catch(() => {})
            }
        },
        tableRowClassName({row, rowIndex}) {
            if (row.IsDeleted && row.Status == 2) {
                return 'delete-row';
            }
            return '';
        },
        // 获取当前行数据
        currentChange(row){
            if(JSON.stringify(row) == '{}'){
                this.$message.error('请选择一行数据')
            }else{
                this.currentRow = row
            }         
        },
        // 分页
        handleSizeChange(value){
            this.pages.PageSize = value
            this.getData()
        },
        handleCurrentChange(value){
            this.pages.PageIndex = value
            this.getData()
        },
        // 查询事件
        GetStudentAchievementListPage(){
            this.pages.PageIndex = 1
            this.getData()
        },
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 查看照片
        viewBatPicture(val) {
            this.dialogTitle = "抓拍照片记录"
            this.viewPictureDialog = true
            var params = {
                studentExamineePaperId: val.ID
            }
            this.getPictureListFun(params);
        },
        // 人声监测
        viewBatSound(val) {
            this.soundDialogTitle = "人声监测记录"
            this.viewSoundDialog = true
            var params = {
                studentExamineePaperId: val.ID,
                status: 0,
            }
            this.getSoundListFun(params);
        },
        // 获取照片列表
        getPictureListFun(params) {
            this.viewPictureLoading = true
            getTakePhotosList(params).then(res => {
                var result = res.data
                this.viewPictureLoading = false
                if(result.Success) {
                    this.viewPictureTable = result.Response
                } else {
                    this.$message.error(result.Message)
                }
            })
        },
        // 获取人声监测列表
        getSoundListFun(params) {
            this.viewSoundLoading = true
            getRecordingMonitoringList(params).then(res => {
                var result = res.data
                this.viewSoundLoading = false
                if(result.Success) {
                    this.viewSoundTable = result.Response
                } else {
                    this.$message.error(result.Message)
                }
            })
        },
        // 表格数据分页条数累计
        indexMethod(index) {
            var currentPage = this.pages.PageIndex
            var pageSize = this.pages.PageSize
            return (index + 1) + (currentPage - 1) * pageSize
        },
        //时间格式化
        formatCreateTime: function(row, column) {
            return !row.CreateTime || row.CreateTime == ""
            ? ""
            : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd");
        },
    },
    created(){
        this.getData();
        this.getStationPaper();
        this.getTradePaper();
    },
    mounted(){
        let routers = window.localStorage.getItem('router')
        ? JSON.parse(window.localStorage.getItem('router'))
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style lang="stylus" scoped>
    // .pageBar{
    //     margin-top:15px!important;
    // }
    .pageBar{
	    height :60px;
    }
    .el-table>>>.delete-row {
        color: #DCDFE6;
    }

    .el-table>>>.success-row {
        background: #f0f9eb;
    }
</style>